<template>
  <section>
      <div class="content-header">
        <h2>Thriving in Action Online Faculty and Staff Guide </h2>
      </div>
      <div class="content-wrapper">
        <p>A common reaction to exploring Thriving in Action (TiA) Online for the first time is “WHOA! There is a lot of stuff here.” For some, the openness of being able to explore the different chapters at their own pace, in their own time, can feel right, or even invigorating.</p>
        <p>However, some students may find the amount of material in TiA Online overwhelming, or they might just prefer a more structured approach to exploring the various concepts.</p>
        <p>In this <a :href="`${publicPath}docs/personal/Faculty-Staff-Guide.pdf`" target="_blank" download>faculty and staff guide</a>, used with permission from Diana Brecher, we provide this more structured approach, suggesting a range of options that include activities for the classroom—in groups or as individual assignments. And suggestions for co-curricular experiences and activities that fit into many different contexts (for students living in Residence,  International students, and so forth).</p>
        <!-- <p> <a href="https://www.ryerson.ca/thriveru/faculty-staff/" target="_blank">https://www.ryerson.ca/thriveru/faculty-staff/</a></p> -->
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '06',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
